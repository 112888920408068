import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import { USER_EXPERIENCES } from '../constants'
import { useToken } from './TokenContext'

export const USER_KEY = 'USER_INFO'

export const AuthContext = React.createContext({})

export const useAuthContext = () => {
  return React.useContext(AuthContext)
}

const defaultUser = null 

function parseUserObject(userToParse, leadToParse, contactToParse) {
  const {
    Contact_Owner_Email__c,
    Contact_Owner_Full_Name__c,
    Contact_Owner_Phone__c,
    Contact_Owner_Photo_Link__c,
    Contact_Owner_Calendar__c,
    ContactId,
    Email,
    UserType,
    IsActive,
  } = userToParse

  return {
    name: leadToParse?.Name?.value ?? contactToParse?.Name?.value,
    Id: leadToParse?.Id ?? contactToParse?.Id,
    contactEmail: Contact_Owner_Email__c?.value,
    contactFullName: Contact_Owner_Full_Name__c?.value,
    contactPhone: Contact_Owner_Phone__c?.value,
    contactPhotoLink: Contact_Owner_Photo_Link__c?.value?.replaceAll('&amp;', '&'),
    contactOwnerCalendarLink: Contact_Owner_Calendar__c?.value,
    contactId: ContactId?.value,
    ownerEmail: Email?.value,
    ownerUserType: UserType?.value,
    ownerIsActive: IsActive?.value,
    isContact: Boolean(contactToParse),
  }
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(defaultUser)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userExperience, setUserExperience] = useState(USER_EXPERIENCES.UNATTESTED)
  const [cookies, setCookie, removeCookie] = useCookies(['userExperience', 'harborCookie'])
  const { harborCookie } = cookies

  const {
    csrfToken,
    isAuthVerified,
    verifyAuthToken,
    logout: tokenLogout,
    loading: tokenLoading,
    error: tokenError,
  } = useToken()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const tryLogin = useCallback(async () => {
    if (typeof window === 'undefined') {
      return
    }

    const userData = JSON.parse(window.sessionStorage.getItem('USER_DATA'))

    if (userData) {
      setUser(userData)
      setUserLoaded(true)
      setLoading(false)
      return
    }

    if (harborCookie && isAuthVerified) {
      const [sfContactId, sfOwnerId] = harborCookie?.split('|').slice(3) ?? []

      if (
        sfContactId &&
        sfOwnerId &&
        sfContactId !== 'null' &&
        sfOwnerId !== 'null'
      ) {
        try {
          const res = await fetch('/api/get-user', {
            headers: {
              'Content-Type': 'application/json',
              'x-csrf-token': csrfToken,
            },
            credentials: 'same-origin',
          })

          if (!res.ok) {
            throw new Error('Failed to fetch user data.')
          }

          const data = await res.json()

          const userObjectResponse =
            data?.user?.data?.uiapi?.query?.User?.edges?.[0]?.node
          const userLeadResponse =
            data?.lead?.data?.uiapi?.query?.Lead?.edges?.[0]?.node
          const userContactResponse =
            data?.contact?.data?.uiapi?.query?.Contact?.edges?.[0]?.node

          if (userObjectResponse && (userLeadResponse || userContactResponse)) {
            const parsedUserObject = parseUserObject(
              userObjectResponse,
              userLeadResponse,
              userContactResponse
            )
            setUser(parsedUserObject)
            window.sessionStorage.setItem('USER_DATA', JSON.stringify(parsedUserObject))
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch user data:', err)
          window.sessionStorage.setItem('USER_DATA', JSON.stringify({}))
          setError(err.message || 'An error occurred during login.')
        } finally {
          setUserLoaded(true)
          setLoading(false)
        }
      } else {
        setUserLoaded(true)
        setLoading(false)
      }
    } else {
      setUserLoaded(true)
      setLoading(false)
    }
  }, [harborCookie, csrfToken])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      tryLogin()
    }
  }, [tryLogin])

  const isLoggedIn = useMemo(
    () =>
      Boolean(user?.contactId) &&
      user.ownerIsActive &&
      user.ownerUserType === 'Standard' &&
      Boolean(user.ownerEmail),
    [user]
  )

  useEffect(() => {
    const isInstitutional = cookies?.userExperience === USER_EXPERIENCES.INSTITUTIONAL_ONLY
    let newUserExperience = cookies?.userExperience
    if (isInstitutional && !isAuthVerified) {
      try {
        const verified = verifyAuthToken()
        if (verified) {
          newUserExperience = USER_EXPERIENCES.INSTITUTIONAL_ONLY
        } else {
          newUserExperience = USER_EXPERIENCES.UNATTESTED
        }
      } catch (e) {
        console.error(e)
      }
    }
    setUserExperience(
      isInstitutional
        ? newUserExperience
        : USER_EXPERIENCES.UNATTESTED
    )
  }, [cookies, isAuthVerified, verifyAuthToken])
  

  const handleLogout = useCallback(async () => {
    try {
      await tokenLogout()
    } catch (err) {
      console.error('Logout failed:', err)
    } finally {
      setUser(defaultUser)
      window.sessionStorage.removeItem('USER_DATA')
      setUserExperience(USER_EXPERIENCES.UNATTESTED)
    }
  }, [tokenLogout, setCookie])

  const value = useMemo(
    () => ({
      state: {
        user,
        isLoggedIn,
        userExperience,
        userLoaded,
        loading: loading || tokenLoading,
        error: error || tokenError,
      },
      actions: {
        tryLogin,
        setUser,
        setUserExperience,
        logout: handleLogout,
      },
    }),
    [
      user,
      isLoggedIn,
      userExperience,
      userLoaded,
      loading,
      tokenLoading,
      error,
      tokenError,
      tryLogin,
      handleLogout,
    ]
  )

  if (!userLoaded) {
    return null
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthContext
