import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'

// Create Modal Context without default values
const ModalContext = createContext()

// Custom hook to use the ModalContext
export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

// ModalProvider component
export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [modalProps, setModalProps] = useState({})

  // Function to show modal, memoized with useCallback
  const showModal = useCallback((type, props = {}) => {
    setModalType(type)
    setModalProps(props)
    setIsOpen(true)
  }, [])

  // Function to hide modal, memoized with useCallback
  const hideModal = useCallback(() => {
    setIsOpen(false)
    setModalType(null)
    setModalProps({})
  }, [])

  useEffect(() => {
    const mainContent = document.getElementById('___gatsby')
    if (!mainContent) return

    document
      .querySelectorAll(
        '#___gatsby button, #___gatsby a, #___gatsby input, #___gatsby select, #___gatsby textarea'
      )
      .forEach((el) =>
        isOpen
          ? el.setAttribute('tabindex', '-1')
          : el.removeAttribute('tabindex')
      )

    setTimeout(() => {
      document
        .querySelectorAll(
          '[data-testid="sentinelStart"], [data-testid="sentinelEnd"]'
        )
        .forEach((el) => {
          el.setAttribute('tabindex', '-1')
        })
    }, 300)
  }, [isOpen])

  // Memoize the context value to optimize performance
  const value = useMemo(
    () => ({
      isOpen,
      modalType,
      modalProps,
      showModal,
      hideModal,
    }),
    [isOpen, modalType, modalProps, showModal, hideModal]
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
