import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useCookies } from 'react-cookie'
import { AuthContext } from './context/AuthContext'
import { USER_EXPERIENCES } from './constants'
import FormModal from '../components/Modal/FormModal'
import { useToken } from './context/TokenContext'
import { PrivatePagesProvider } from './context/PrivatePagesContext'

const isWindowDefined = () => typeof window !== 'undefined'

const GatedContentHandler = ({ children, location }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [isPending, startTransition] = useTransition()
  const [harborCookieFetched, setHarborCookieFetched] = useState(false)

  const data = useStaticQuery(graphql`
    query GatedContentHandlerQuery {
      allContentstackContactUsLoggedIn(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightArticle {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackLandingPage(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackFaForm(filter: { form_id: { in: [29, 30] } }) {
        nodes {
          form_id
          test_form_id
          form_cta_text
          custom_validations
        }
      }
    }
  `)

  if (!data) {
    console.warn('GatedContentHandler query returned no data.')
  }

  const [cookies] = useCookies(['harborCookie'])
  const { harborCookie } = cookies

  const authCtx = useContext(AuthContext)
  const { csrfToken, isAuthVerified } = useToken()

  const { pathname, search, state } = location || {}
  const { data: dataLocation } = state || {}

  const privatePages = useMemo(() => {
    const routes = [
      ...(data.allContentstackContactUsLoggedIn?.nodes || []),
      ...(data.allContentstackInsightArticle?.nodes || []),
      ...(data.allContentstackLandingPage?.nodes || []),
    ]

    return routes
      .filter(
        (route) =>
          route.user_experience?.experience ===
          USER_EXPERIENCES.INSTITUTIONAL_ONLY
      )
      .map((route) => route.url)
  }, [data])

  const queryParams = useMemo(
    () =>
      isWindowDefined() ? new URLSearchParams(search) : new URLSearchParams(),
    [search]
  )

  const modalKey = useMemo(() => queryParams.get('form'), [queryParams])

  const faForms = data?.allContentstackFaForm?.nodes || []
  const formData = useMemo(() => {
    const mapping = {}
    faForms.forEach((form) => {
      const idToUse =
        process.env.NODE_ENV === 'production' ? form.form_id : form.test_form_id
      if (form.form_id === 29 || form.form_id === 33) {
        mapping.login = {
          formId: idToUse,
          formCta: form.form_cta_text,
          customValidation: form.custom_validations,
        }
      } else if (form.form_id === 30 || form.form_id === 34) {
        mapping.register = {
          formId: idToUse,
          formCta: form.form_cta_text,
          customValidation: form.custom_validations,
        }
      }
    })
    return mapping
  }, [faForms])

  const formId = formData[modalKey]?.formId
  const formCta = formData[modalKey]?.formCta
  const customValidation = formData[modalKey]?.customValidation

  const handleModalClose = useCallback(() => {
    startTransition(() => setModalOpen(false))
    if (isWindowDefined()) {
      const updatedQueryParams = new URLSearchParams(search)
      updatedQueryParams.delete('form')
      const newSearch = updatedQueryParams.toString()
      const newUrl = `${pathname}${newSearch ? `?${newSearch}` : ''}`
      navigate(newUrl, { replace: true })
    }
  }, [pathname, search, startTransition])

  useEffect(() => {
    if (isWindowDefined()) {
      setIsClient(true)
    }
  }, [])

  useEffect(() => {
    const newModalState = !!formId
    setModalOpen((prevState) => (prevState === newModalState ? prevState : newModalState))
  }, [formId])
  

  const fetchHarborCookie = async () => {
    if (!csrfToken) {
      throw new Error('CSRF token is missing')
    }

    const res = await fetch('/api/harbor-cookie', {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken,
      },
      credentials: 'same-origin',
    })

    if (!res.ok) {
      throw new Error(`Failed to fetch harbor-cookie: ${res.statusText}`)
    }

    authCtx.actions.tryLogin()
    setHarborCookieFetched(true)
  }

  const shouldFetchHarborCookie = () => {
    const hasValidHarborCookie =
      harborCookie && !harborCookie.split('|').includes('null')

    return isAuthVerified && !harborCookieFetched && !hasValidHarborCookie
  }

  useEffect(() => {
    if (shouldFetchHarborCookie() && csrfToken) {
      fetchHarborCookie().catch((error) => {
        console.error('Error fetching harbor-cookie:', error)
      })
    }
  }, [harborCookie, csrfToken, harborCookieFetched, isAuthVerified])

  if (!isClient) {
    return null
  }

  return (
    <PrivatePagesProvider privatePages={privatePages}>
      <>
        {children}
        {isModalOpen && formId && formCta && (
          <FormModal
            onClose={handleModalClose}
            isOpen={isModalOpen}
            formId={formId}
            customValidation={customValidation}
            isModal
            ctaText={formCta}
            dataLocation={dataLocation}
          />
        )}
      </>
    </PrivatePagesProvider>
  )
}

export default React.memo(GatedContentHandler)
